import React from 'react'

import { Helmet } from 'react-helmet'

import './mockup.css'

const Mockup = (props) => {
  return (
    <div className="mockup-container">
      <Helmet>
        <title>exported project</title>
      </Helmet>
      <div className="mockup-jobofferplatform">
        <div className="mockup-content">
          <div className="mockup-frame15">
            <div className="mockup-filter">
              <div className="mockup-frame36">
                <span className="mockup-text">
                  <span>Filters</span>
                </span>
                <div className="mockup-frame35">
                  <span className="mockup-text002">
                    <span>Location</span>
                  </span>
                  <div className="mockup-frame34">
                    <div className="mockup-frame31">
                      <img
                        alt="Ellipse54826"
                        src="/external/ellipse54826-0ysr-200h.png"
                        className="mockup-ellipse5"
                      />
                      <span className="mockup-text004">
                        <span>Near me</span>
                      </span>
                    </div>
                    <div className="mockup-frame32">
                      <div className="mockup-group1">
                        <img
                          alt="Ellipse64826"
                          src="/external/ellipse64826-edob-200h.png"
                          className="mockup-ellipse6"
                        />
                        <img
                          alt="Ellipse74826"
                          src="/external/ellipse74826-j43d-200h.png"
                          className="mockup-ellipse7"
                        />
                      </div>
                      <span className="mockup-text006">
                        <span>Remote job</span>
                      </span>
                    </div>
                    <div className="mockup-frame33">
                      <img
                        alt="Ellipse53360"
                        src="/external/ellipse53360-2mxc-200h.png"
                        className="mockup-ellipse501"
                      />
                      <span className="mockup-text008">
                        <span>Exact location</span>
                      </span>
                    </div>
                    <div className="mockup-frame341">
                      <img
                        alt="Ellipse53363"
                        src="/external/ellipse53363-xqid-200h.png"
                        className="mockup-ellipse502"
                      />
                      <span className="mockup-text010">
                        <span>Within 15 km</span>
                      </span>
                    </div>
                    <div className="mockup-frame351">
                      <img
                        alt="Ellipse53366"
                        src="/external/ellipse53366-k0tn-200h.png"
                        className="mockup-ellipse503"
                      />
                      <span className="mockup-text012">
                        <span>Within 30 km</span>
                      </span>
                    </div>
                    <div className="mockup-frame361">
                      <img
                        alt="Ellipse53369"
                        src="/external/ellipse53369-sbso-200h.png"
                        className="mockup-ellipse504"
                      />
                      <span className="mockup-text014">
                        <span>Within 50 km</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame37">
                  <span className="mockup-text016">
                    <span>Salary</span>
                  </span>
                  <div className="mockup-frame43">
                    <div className="mockup-frame40">
                      <span className="mockup-text018">
                        <span>Hourly</span>
                      </span>
                    </div>
                    <div className="mockup-frame41">
                      <span className="mockup-text020">
                        <span>Monthly</span>
                      </span>
                    </div>
                    <div className="mockup-frame42">
                      <span className="mockup-text022">
                        <span>Yearly</span>
                      </span>
                    </div>
                  </div>
                  <div className="mockup-frame342">
                    <div className="mockup-frame311">
                      <div className="mockup-group11">
                        <img
                          alt="Ellipse63310"
                          src="/external/ellipse63310-p4cl-200h.png"
                          className="mockup-ellipse61"
                        />
                        <img
                          alt="Ellipse73310"
                          src="/external/ellipse73310-xzy-200h.png"
                          className="mockup-ellipse71"
                        />
                      </div>
                      <span className="mockup-text024">
                        <span>Any</span>
                      </span>
                    </div>
                    <div className="mockup-frame321">
                      <img
                        alt="Ellipse53310"
                        src="/external/ellipse53310-rexm-200h.png"
                        className="mockup-ellipse505"
                      />
                      <span className="mockup-text026">
                        <span>&gt; 30000k</span>
                      </span>
                    </div>
                    <div className="mockup-frame331">
                      <img
                        alt="Ellipse53310"
                        src="/external/ellipse53310-rx8-200h.png"
                        className="mockup-ellipse506"
                      />
                      <span className="mockup-text028">
                        <span>&gt; 50000k</span>
                      </span>
                    </div>
                    <div className="mockup-frame343">
                      <img
                        alt="Ellipse53311"
                        src="/external/ellipse53311-3uh4-200h.png"
                        className="mockup-ellipse507"
                      />
                      <span className="mockup-text030">
                        <span>&gt; 80000k</span>
                      </span>
                    </div>
                    <div className="mockup-frame352">
                      <img
                        alt="Ellipse53311"
                        src="/external/ellipse53311-hyx-200h.png"
                        className="mockup-ellipse508"
                      />
                      <span className="mockup-text032">
                        <span>&gt; 100000k</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame362">
                  <span className="mockup-text034">
                    <span>Date of posting</span>
                  </span>
                  <div className="mockup-frame344">
                    <div className="mockup-frame312">
                      <div className="mockup-group12">
                        <img
                          alt="Ellipse63376"
                          src="/external/ellipse63376-8dch-200h.png"
                          className="mockup-ellipse62"
                        />
                        <img
                          alt="Ellipse73377"
                          src="/external/ellipse73377-e3l-200h.png"
                          className="mockup-ellipse72"
                        />
                      </div>
                      <span className="mockup-text036">
                        <span>All time</span>
                      </span>
                    </div>
                    <div className="mockup-frame322">
                      <img
                        alt="Ellipse53380"
                        src="/external/ellipse53380-vtc-200h.png"
                        className="mockup-ellipse509"
                      />
                      <span className="mockup-text038">
                        <span>Last 24 hours</span>
                      </span>
                    </div>
                    <div className="mockup-frame332">
                      <img
                        alt="Ellipse53383"
                        src="/external/ellipse53383-tgne-200h.png"
                        className="mockup-ellipse510"
                      />
                      <span className="mockup-text040">
                        <span>Last 3 days</span>
                      </span>
                    </div>
                    <div className="mockup-frame345">
                      <img
                        alt="Ellipse53386"
                        src="/external/ellipse53386-ydp9-200h.png"
                        className="mockup-ellipse511"
                      />
                      <span className="mockup-text042">
                        <span>Last 7 days</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame38">
                  <span className="mockup-text044">
                    <span>Work experience</span>
                  </span>
                  <div className="mockup-frame346">
                    <div className="mockup-frame313">
                      <div className="mockup-group13">
                        <img
                          alt="Ellipse63312"
                          src="/external/ellipse63312-5e2g-200h.png"
                          className="mockup-ellipse63"
                        />
                        <img
                          alt="Ellipse73312"
                          src="/external/ellipse73312-5nd3-200h.png"
                          className="mockup-ellipse73"
                        />
                      </div>
                      <span className="mockup-text046">
                        <span>Any experience</span>
                      </span>
                    </div>
                    <div className="mockup-frame323">
                      <img
                        alt="Ellipse53312"
                        src="/external/ellipse53312-avrw-200h.png"
                        className="mockup-ellipse512"
                      />
                      <span className="mockup-text048">
                        <span>Intership</span>
                      </span>
                    </div>
                    <div className="mockup-frame333">
                      <img
                        alt="Ellipse53312"
                        src="/external/ellipse53312-6kfh-200h.png"
                        className="mockup-ellipse513"
                      />
                      <span className="mockup-text050">
                        <span>Work remotely</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame39">
                  <span className="mockup-text052">
                    <span>Type of employment</span>
                  </span>
                  <div className="mockup-frame347">
                    <div className="mockup-frame314">
                      <div className="mockup-group4">
                        <img
                          alt="Rectangle24876"
                          src="/external/rectangle24876-6ps-200h.png"
                          className="mockup-rectangle2"
                        />
                        <img
                          alt="Check4878"
                          src="/external/check4878-90b.svg"
                          className="mockup-check"
                        />
                      </div>
                      <span className="mockup-text054">
                        <span>Full-time</span>
                      </span>
                    </div>
                    <div className="mockup-frame324">
                      <img
                        alt="Rectangle24875"
                        src="/external/rectangle24875-3o1-200h.png"
                        className="mockup-rectangle21"
                      />
                      <span className="mockup-text056">
                        <span>Temporary</span>
                      </span>
                    </div>
                    <div className="mockup-frame334">
                      <img
                        alt="Rectangle34878"
                        src="/external/rectangle34878-oh9x-200h.png"
                        className="mockup-rectangle3"
                      />
                      <span className="mockup-text058">
                        <span>Part-time</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mockup-cards">
              <div className="mockup-frame71">
                <div className="mockup-frame46">
                  <span className="mockup-text060">
                    <span>3177 Jobs</span>
                  </span>
                  <div className="mockup-frame14">
                    <div className="mockup-frame45">
                      <img
                        alt="FunnelSimple3745"
                        src="/external/funnelsimple3745-utoo.svg"
                        className="mockup-funnel-simple"
                      />
                      <span className="mockup-text062">
                        <span>Filter by</span>
                      </span>
                    </div>
                    <img
                      alt="CaretDown3750"
                      src="/external/caretdown3750-x9eu.svg"
                      className="mockup-caret-down"
                    />
                  </div>
                </div>
                <div className="mockup-frame26">
                  <div className="mockup-frame17"></div>
                  <div className="mockup-frame23">
                    <div className="mockup-frame28">
                      <div className="mockup-container1">
                        <div className="mockup-frame29">
                          <div className="mockup-frame27">
                            <span className="mockup-text064">
                              <span>Linear company</span>
                            </span>
                          </div>
                          <div className="mockup-frame30">
                            <span className="mockup-text066">
                              <span>Software Engineer</span>
                            </span>
                            <div className="mockup-frame25">
                              <span className="mockup-text068">
                                <span>New post</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <svg viewBox="0 0 1024 1024" className="mockup-icon">
                          <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM469.333 256v256c0 16.597 9.472 31.019 23.595 38.144l170.667 85.333c21.077 10.539 46.72 2.005 57.259-19.072s2.005-46.72-19.072-57.259l-147.115-73.515v-229.632c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                        </svg>
                      </div>
                      <div className="mockup-frame3001">
                        <div className="mockup-frame18">
                          <img
                            alt="MapPinLine2391"
                            src="/external/mappinline2391-jsp.svg"
                            className="mockup-map-pin-line"
                          />
                          <span className="mockup-text070">
                            <span>Brussels</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse32396"
                          src="/external/ellipse32396-yefx-200h.png"
                          className="mockup-ellipse3"
                        />
                        <div className="mockup-frame20">
                          <span className="mockup-text072">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse22310"
                          src="/external/ellipse22310-dljp-200h.png"
                          className="mockup-ellipse2"
                        />
                        <div className="mockup-frame21">
                          <img
                            alt="CurrencyDollar2310"
                            src="/external/currencydollar2310-73qc.svg"
                            className="mockup-currency-dollar"
                          />
                          <span className="mockup-text074">
                            <span>50-55k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse12310"
                          src="/external/ellipse12310-u7tl-200h.png"
                          className="mockup-ellipse1"
                        />
                        <div className="mockup-frame22">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="mockup-clock"
                          />
                          <span className="mockup-text076">
                            <span>29 min ago</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame271">
                  <div className="mockup-frame171"></div>
                  <div className="mockup-frame231">
                    <div className="mockup-frame281">
                      <div className="mockup-frame291">
                        <div className="mockup-frame272">
                          <span className="mockup-text078">
                            <span>Notion</span>
                          </span>
                        </div>
                        <div className="mockup-frame3002">
                          <span className="mockup-text080">
                            <span>Junior UI Designer</span>
                          </span>
                        </div>
                      </div>
                      <div className="mockup-frame3003">
                        <div className="mockup-frame181">
                          <img
                            alt="MapPinLine2311"
                            src="/external/mappinline2311-86tn.svg"
                            className="mockup-map-pin-line1"
                          />
                          <span className="mockup-text082">
                            <span>Madrid</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse32312"
                          src="/external/ellipse32312-fh0c-200h.png"
                          className="mockup-ellipse31"
                        />
                        <div className="mockup-frame201">
                          <span className="mockup-text084">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse22312"
                          src="/external/ellipse22312-utz9-200h.png"
                          className="mockup-ellipse21"
                        />
                        <div className="mockup-frame211">
                          <img
                            alt="CurrencyDollar2313"
                            src="/external/currencydollar2313-nf3u.svg"
                            className="mockup-currency-dollar1"
                          />
                          <span className="mockup-text086">
                            <span>30-32k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse12313"
                          src="/external/ellipse12313-egvm-200h.png"
                          className="mockup-ellipse11"
                        />
                        <div className="mockup-frame221">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="mockup-clock1"
                          />
                          <span className="mockup-text088">1 hour ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame48">
                  <div className="mockup-frame172"></div>
                  <div className="mockup-frame232">
                    <div className="mockup-frame282">
                      <div className="mockup-frame292">
                        <div className="mockup-frame273">
                          <span className="mockup-text089">
                            <span>Spline studio</span>
                          </span>
                        </div>
                        <div className="mockup-frame3004">
                          <span className="mockup-text091">
                            <span>Technical Support Engineer</span>
                          </span>
                        </div>
                      </div>
                      <div className="mockup-frame3005">
                        <div className="mockup-frame182">
                          <img
                            alt="MapPinLine4756"
                            src="/external/mappinline4756-hddb.svg"
                            className="mockup-map-pin-line2"
                          />
                          <span className="mockup-text093">
                            <span>United States</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse34761"
                          src="/external/ellipse34761-ujf4-200h.png"
                          className="mockup-ellipse32"
                        />
                        <div className="mockup-frame202">
                          <span className="mockup-text095">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse24767"
                          src="/external/ellipse24767-zu9-200h.png"
                          className="mockup-ellipse22"
                        />
                        <div className="mockup-frame212">
                          <img
                            alt="CurrencyDollar4769"
                            src="/external/currencydollar4769-u6ye.svg"
                            className="mockup-currency-dollar2"
                          />
                          <span className="mockup-text097">
                            <span>50-52k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse14773"
                          src="/external/ellipse14773-aixm-200h.png"
                          className="mockup-ellipse12"
                        />
                        <div className="mockup-frame222">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="mockup-clock2"
                          />
                          <span className="mockup-text099">9 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame251">
                  <div className="mockup-frame173"></div>
                  <div className="mockup-frame233">
                    <div className="mockup-frame283">
                      <div className="mockup-frame293">
                        <div className="mockup-frame274">
                          <span className="mockup-text100">
                            <span>Raycast corp</span>
                          </span>
                        </div>
                        <div className="mockup-frame3006">
                          <span className="mockup-text102">
                            <span>Product Designer</span>
                          </span>
                        </div>
                      </div>
                      <div className="mockup-frame3007">
                        <div className="mockup-frame183">
                          <img
                            alt="MapPinLine2364"
                            src="/external/mappinline2364-im1e.svg"
                            className="mockup-map-pin-line3"
                          />
                          <span className="mockup-text104">
                            <span>London</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse32369"
                          src="/external/ellipse32369-ka9m-200h.png"
                          className="mockup-ellipse33"
                        />
                        <div className="mockup-frame203">
                          <span className="mockup-text106">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse22375"
                          src="/external/ellipse22375-fxu-200h.png"
                          className="mockup-ellipse23"
                        />
                        <div className="mockup-frame213">
                          <img
                            alt="CurrencyDollar2377"
                            src="/external/currencydollar2377-tzn.svg"
                            className="mockup-currency-dollar3"
                          />
                          <span className="mockup-text108">
                            <span>40-42k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse12381"
                          src="/external/ellipse12381-qh5s-200h.png"
                          className="mockup-ellipse13"
                        />
                        <div className="mockup-frame223">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="mockup-clock3"
                          />
                          <span className="mockup-text110">12 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame47">
                  <div className="mockup-frame174"></div>
                  <div className="mockup-frame234">
                    <div className="mockup-frame284">
                      <div className="mockup-frame294">
                        <div className="mockup-frame275">
                          <span className="mockup-text111">
                            <span>Loom</span>
                          </span>
                        </div>
                        <div className="mockup-frame3008">
                          <span className="mockup-text113">
                            <span>Copywriter (Growth)</span>
                          </span>
                        </div>
                      </div>
                      <div className="mockup-frame3009">
                        <div className="mockup-frame184">
                          <img
                            alt="MapPinLine4719"
                            src="/external/mappinline4719-slju.svg"
                            className="mockup-map-pin-line4"
                          />
                          <span className="mockup-text115">
                            <span>London</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse34724"
                          src="/external/ellipse34724-duqc-200h.png"
                          className="mockup-ellipse34"
                        />
                        <div className="mockup-frame204">
                          <span className="mockup-text117">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse24730"
                          src="/external/ellipse24730-3vqf-200h.png"
                          className="mockup-ellipse24"
                        />
                        <div className="mockup-frame214">
                          <img
                            alt="CurrencyDollar4732"
                            src="/external/currencydollar4732-o4mu.svg"
                            className="mockup-currency-dollar4"
                          />
                          <span className="mockup-text119">
                            <span>38-40k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse14736"
                          src="/external/ellipse14736-wzgh-200h.png"
                          className="mockup-ellipse14"
                        />
                        <div className="mockup-frame224">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="mockup-clock4"
                          />
                          <span className="mockup-text121">15 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mockup-frame24">
                  <div className="mockup-frame175"></div>
                  <div className="mockup-frame235">
                    <div className="mockup-frame285">
                      <div className="mockup-frame295">
                        <div className="mockup-frame276">
                          <span className="mockup-text122">
                            <span>Trainline group</span>
                          </span>
                        </div>
                        <div className="mockup-frame3010">
                          <span className="mockup-text124">
                            <span>Senior UX/UI Designer</span>
                          </span>
                        </div>
                      </div>
                      <div className="mockup-frame225">
                        <div className="mockup-frame185">
                          <img
                            alt="MapPinLine1716"
                            src="/external/mappinline1716-wco.svg"
                            className="mockup-map-pin-line5"
                          />
                          <span className="mockup-text126">
                            <span>Paris</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse31716"
                          src="/external/ellipse31716-r68it-200h.png"
                          className="mockup-ellipse35"
                        />
                        <div className="mockup-frame205">
                          <img
                            alt="Clock1717"
                            src="/external/clock1717-nt2q.svg"
                            className="mockup-clock5"
                          />
                          <span className="mockup-text128">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse21717"
                          src="/external/ellipse21717-7ohf-200h.png"
                          className="mockup-ellipse25"
                        />
                        <div className="mockup-frame215">
                          <img
                            alt="CurrencyDollar1717"
                            src="/external/currencydollar1717-e33s.svg"
                            className="mockup-currency-dollar5"
                          />
                          <span className="mockup-text130">
                            <span>38-40k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse11718"
                          src="/external/ellipse11718-0it-200h.png"
                          className="mockup-ellipse15"
                        />
                        <div className="mockup-frame226">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="mockup-clock6"
                          />
                          <span className="mockup-text132">20 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mockup-pagination">
                <button className="mockup-button">
                  <img
                    alt="ChevronDown4712"
                    src="/external/chevrondown4712-w4l6.svg"
                    className="mockup-chevron-down"
                  />
                </button>
                <button className="mockup-button01">
                  <span className="mockup-text133">1</span>
                </button>
                <button className="mockup-button02">
                  <span className="mockup-text134">2</span>
                </button>
                <button className="mockup-button03">
                  <span className="mockup-text135">3</span>
                </button>
                <button className="mockup-button04">
                  <span className="mockup-text136">4</span>
                </button>
                <button className="mockup-button05">
                  <span className="mockup-text137">5</span>
                </button>
                <button className="mockup-button06">
                  <img
                    alt="ChevronDown4713"
                    src="/external/chevrondown4713-y7v.svg"
                    className="mockup-chevron-down1"
                  />
                </button>
              </div>
            </div>
            <div className="mockup-moreoption">
              <div className="mockup-frame16">
                <div className="mockup-frame363">
                  <span className="mockup-text138">
                    <span>📨 Email me for jobs</span>
                  </span>
                </div>
                <div className="mockup-frame371">
                  <span className="mockup-text140">
                    <span>
                      Ut esse eiusmod aute. Sit enim labore dolore. Aute ea
                      fugiat commodo ea foes.
                    </span>
                  </span>
                </div>
                <div className="mockup-frame72">
                  <div className="mockup-frame141">
                    <span className="mockup-text142">
                      <span>name@mail.com</span>
                    </span>
                  </div>
                  <button className="mockup-button07">
                    <span className="mockup-text144">
                      <span>Subcribe</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="mockup-frame186">
                <div className="mockup-frame364">
                  <span className="mockup-text146">
                    <span>🚀 Get noticed faster</span>
                  </span>
                </div>
                <div className="mockup-frame372">
                  <span className="mockup-text148">
                    <span>
                      Quis eiusmod deserunt cillum laboris magna cupidatat esse
                      labore irure quis cupidatat in.
                    </span>
                  </span>
                </div>
                <button className="mockup-button08">
                  <span className="mockup-text150">
                    <span>Upload your resume</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mockup-search">
          <div className="mockup-group2">
            <div className="mockup-group">
              <div className="mockup-frame2">
                <div className="mockup-frame60">
                  <div className="mockup-frame4801"></div>
                  <div className="mockup-frame49"></div>
                  <div className="mockup-frame50"></div>
                  <div className="mockup-frame51"></div>
                  <div className="mockup-frame52"></div>
                  <div className="mockup-frame53"></div>
                  <div className="mockup-frame54"></div>
                  <div className="mockup-frame55"></div>
                  <div className="mockup-frame56"></div>
                  <div className="mockup-frame57"></div>
                  <div className="mockup-frame58"></div>
                  <div className="mockup-frame59"></div>
                </div>
                <div className="mockup-frame61">
                  <div className="mockup-frame4802"></div>
                  <div className="mockup-frame4901"></div>
                  <div className="mockup-frame5001"></div>
                  <div className="mockup-frame5101"></div>
                  <div className="mockup-frame5201"></div>
                  <div className="mockup-frame5301"></div>
                  <div className="mockup-frame5401"></div>
                  <div className="mockup-frame5501"></div>
                  <div className="mockup-frame5601"></div>
                  <div className="mockup-frame5701"></div>
                  <div className="mockup-frame5801"></div>
                  <div className="mockup-frame5901"></div>
                </div>
                <div className="mockup-frame62">
                  <div className="mockup-frame4803"></div>
                  <div className="mockup-frame4902"></div>
                  <div className="mockup-frame5002"></div>
                  <div className="mockup-frame5102"></div>
                  <div className="mockup-frame5202"></div>
                  <div className="mockup-frame5302"></div>
                  <div className="mockup-frame5402"></div>
                  <div className="mockup-frame5502"></div>
                  <div className="mockup-frame5602"></div>
                  <div className="mockup-frame5702"></div>
                  <div className="mockup-frame5802"></div>
                  <div className="mockup-frame5902"></div>
                </div>
                <div className="mockup-frame63">
                  <div className="mockup-frame4804"></div>
                  <div className="mockup-frame4903"></div>
                  <div className="mockup-frame5003"></div>
                  <div className="mockup-frame5103"></div>
                  <div className="mockup-frame5203"></div>
                  <div className="mockup-frame5303"></div>
                  <div className="mockup-frame5403"></div>
                  <div className="mockup-frame5503"></div>
                  <div className="mockup-frame5603"></div>
                  <div className="mockup-frame5703"></div>
                  <div className="mockup-frame5803"></div>
                  <div className="mockup-frame5903"></div>
                </div>
                <div className="mockup-frame64">
                  <div className="mockup-frame4805"></div>
                  <div className="mockup-frame4904"></div>
                  <div className="mockup-frame5004"></div>
                  <div className="mockup-frame5104"></div>
                  <div className="mockup-frame5204"></div>
                  <div className="mockup-frame5304"></div>
                  <div className="mockup-frame5404"></div>
                  <div className="mockup-frame5504"></div>
                  <div className="mockup-frame5604"></div>
                  <div className="mockup-frame5704"></div>
                  <div className="mockup-frame5804"></div>
                  <div className="mockup-frame5904"></div>
                </div>
                <div className="mockup-frame65">
                  <div className="mockup-frame4806"></div>
                  <div className="mockup-frame4905"></div>
                  <div className="mockup-frame5005"></div>
                  <div className="mockup-frame5105"></div>
                  <div className="mockup-frame5205"></div>
                  <div className="mockup-frame5305"></div>
                  <div className="mockup-frame5405"></div>
                  <div className="mockup-frame5505"></div>
                  <div className="mockup-frame5605"></div>
                  <div className="mockup-frame5705"></div>
                  <div className="mockup-frame5805"></div>
                  <div className="mockup-frame5905"></div>
                </div>
                <div className="mockup-frame66">
                  <div className="mockup-frame4807"></div>
                  <div className="mockup-frame4906"></div>
                  <div className="mockup-frame5006"></div>
                  <div className="mockup-frame5106"></div>
                  <div className="mockup-frame5206"></div>
                  <div className="mockup-frame5306"></div>
                  <div className="mockup-frame5406"></div>
                  <div className="mockup-frame5506"></div>
                  <div className="mockup-frame5606"></div>
                  <div className="mockup-frame5706"></div>
                  <div className="mockup-frame5806"></div>
                  <div className="mockup-frame5906"></div>
                </div>
                <div className="mockup-frame67">
                  <div className="mockup-frame4808"></div>
                  <div className="mockup-frame4907"></div>
                  <div className="mockup-frame5007"></div>
                  <div className="mockup-frame5107"></div>
                  <div className="mockup-frame5207"></div>
                  <div className="mockup-frame5307"></div>
                  <div className="mockup-frame5407"></div>
                  <div className="mockup-frame5507"></div>
                  <div className="mockup-frame5607"></div>
                  <div className="mockup-frame5707"></div>
                  <div className="mockup-frame5807"></div>
                  <div className="mockup-frame5907"></div>
                </div>
                <div className="mockup-frame68">
                  <div className="mockup-frame4809"></div>
                  <div className="mockup-frame4908"></div>
                  <div className="mockup-frame5008"></div>
                  <div className="mockup-frame5108"></div>
                  <div className="mockup-frame5208"></div>
                  <div className="mockup-frame5308"></div>
                  <div className="mockup-frame5408"></div>
                  <div className="mockup-frame5508"></div>
                  <div className="mockup-frame5608"></div>
                  <div className="mockup-frame5708"></div>
                  <div className="mockup-frame5808"></div>
                  <div className="mockup-frame5908"></div>
                </div>
                <div className="mockup-frame69">
                  <div className="mockup-frame4810"></div>
                  <div className="mockup-frame4909"></div>
                  <div className="mockup-frame5009"></div>
                  <div className="mockup-frame5109"></div>
                  <div className="mockup-frame5209"></div>
                  <div className="mockup-frame5309"></div>
                  <div className="mockup-frame5409"></div>
                  <div className="mockup-frame5509"></div>
                  <div className="mockup-frame5609"></div>
                  <div className="mockup-frame5709"></div>
                  <div className="mockup-frame5809"></div>
                  <div className="mockup-frame5909"></div>
                </div>
              </div>
            </div>
            <img
              alt="Rectangle14042"
              src="/external/rectangle14042-wx9u-1200h.png"
              className="mockup-rectangle1"
            />
          </div>
          <div className="mockup-frame12">
            <div className="mockup-frame11">
              <span className="mockup-text152">
                <span className="mockup-text153">
                  Find your
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="mockup-text154">new job</span>
                <span> today</span>
              </span>
              <span className="mockup-text156">
                <span>
                  Thousands of jobs in the computer, engineering and technology
                  sectors are waiting for you.
                </span>
              </span>
            </div>
            <div className="mockup-frame10">
              <div className="mockup-frame9">
                <img
                  alt="MagnifyingGlass3337"
                  src="/external/magnifyingglass3337-i1je.svg"
                  className="mockup-magnifying-glass"
                />
                <span className="mockup-text158">
                  <span>What position are you looking for ?</span>
                </span>
              </div>
              <div className="mockup-frame142">
                <img
                  alt="MapPinLine3338"
                  src="/external/mappinline3338-iqw.svg"
                  className="mockup-map-pin-line6"
                />
                <span className="mockup-text160">
                  <span>Location</span>
                </span>
              </div>
              <button className="mockup-button09">
                <span className="mockup-text162">
                  <span>Search job</span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="mockup-navbar">
          <div className="mockup-container2">
            <div className="mockup-frame471">
              <div className="mockup-group3">
                <img
                  alt="Ellipse84042"
                  src="/external/ellipse84042-zla8-200h.png"
                  className="mockup-ellipse8"
                />
                <img
                  alt="Ellipse94042"
                  src="/external/ellipse94042-xdf3-200h.png"
                  className="mockup-ellipse9"
                />
              </div>
              <span className="mockup-text164">JobFresh</span>
            </div>
            <div className="mockup-column">
              <span className="mockup-text165">Home</span>
              <span className="mockup-text166">Jobs list</span>
              <span className="mockup-text167">About</span>
              <span className="mockup-text168">Blog</span>
            </div>
            <div className="mockup-column1">
              <button className="mockup-button10">
                <span className="mockup-text169">
                  <span>Log in</span>
                </span>
              </button>
              <button className="mockup-button11">
                <span className="mockup-text171">
                  <span>Sign up</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mockup
