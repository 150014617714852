import React from 'react'

import { Helmet } from 'react-helmet'

import './jobs-list.css'

const JobsList = (props) => {
  return (
    <div className="jobs-list-container">
      <Helmet>
        <title>Jobs-List - exported project</title>
        <meta property="og:title" content="Jobs-List - exported project" />
      </Helmet>
      <div className="jobs-list-navbar">
        <div className="jobs-list-container1">
          <div className="jobs-list-frame47">
            <div className="jobs-list-group3">
              <img
                alt="Ellipse84042"
                src="/external/ellipse84042-zla8-200h.png"
                className="jobs-list-ellipse8"
              />
              <img
                alt="Ellipse94042"
                src="/external/ellipse94042-xdf3-200h.png"
                className="jobs-list-ellipse9"
              />
            </div>
            <span className="jobs-list-text">JobFresh</span>
          </div>
          <div className="jobs-list-column">
            <span className="jobs-list-text001">Home</span>
            <span className="jobs-list-text002">Jobs list</span>
            <span className="jobs-list-text003">About</span>
            <span className="jobs-list-text004">Blog</span>
          </div>
          <div className="jobs-list-column1">
            <button className="jobs-list-button">
              <span className="jobs-list-text005">
                <span>Log in</span>
              </span>
            </button>
            <button className="jobs-list-button01">
              <span className="jobs-list-text007">
                <span>Sign up</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="jobs-list-frame11"></div>
      <div className="jobs-list-container2">
        <span className="jobs-list-text009">
          Thousands of jobs in the computer, engineering and technology sectors
          are waiting for you.
        </span>
        <span className="jobs-list-text010">
          <span className="jobs-list-text011">
            Find your
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="jobs-list-text012">new job</span>
          <span className="jobs-list-text013"> today</span>
        </span>
      </div>
      <div className="jobs-list-container3">
        <div className="jobs-list-frame9">
          <img
            alt="MagnifyingGlass3337"
            src="/external/magnifyingglass3337-i1je.svg"
            className="jobs-list-magnifying-glass"
          />
          <span className="jobs-list-text014">
            <span>What position are you looking for ?</span>
          </span>
        </div>
        <div className="jobs-list-frame14">
          <img
            alt="MapPinLine3338"
            src="/external/mappinline3338-iqw.svg"
            className="jobs-list-map-pin-line"
          />
          <span className="jobs-list-text016">
            <span>Location</span>
          </span>
        </div>
        <button className="jobs-list-button02">
          <span className="jobs-list-text018">
            <span>Search job</span>
          </span>
        </button>
      </div>
      <div className="jobs-list-container4">
        <div className="jobs-list-content">
          <div className="jobs-list-frame15">
            <div className="jobs-list-filter">
              <div className="jobs-list-frame36">
                <span className="jobs-list-text020">
                  <span>Filters</span>
                </span>
                <div className="jobs-list-frame35">
                  <span className="jobs-list-text022">
                    <span>Skills</span>
                    <br></br>
                  </span>
                  <input
                    type="text"
                    placeholder="placeholder"
                    className="input jobs-list-textinput"
                  />
                </div>
                <div className="jobs-list-frame37">
                  <span className="jobs-list-text025">
                    <span>Salary</span>
                  </span>
                  <div className="jobs-list-frame43">
                    <div className="jobs-list-frame40">
                      <span className="jobs-list-text027">
                        <span>Hourly</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame41">
                      <span className="jobs-list-text029">
                        <span>Monthly</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame42">
                      <span className="jobs-list-text031">
                        <span>Yearly</span>
                      </span>
                    </div>
                  </div>
                  <div className="jobs-list-frame34">
                    <div className="jobs-list-frame31">
                      <div className="jobs-list-group1">
                        <img
                          alt="Ellipse63310"
                          src="/external/ellipse63310-p4cl-200h.png"
                          className="jobs-list-ellipse6"
                        />
                        <img
                          alt="Ellipse73310"
                          src="/external/ellipse73310-xzy-200h.png"
                          className="jobs-list-ellipse7"
                        />
                      </div>
                      <span className="jobs-list-text033">
                        <span>Any</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame32">
                      <img
                        alt="Ellipse53310"
                        src="/external/ellipse53310-rexm-200h.png"
                        className="jobs-list-ellipse5"
                      />
                      <span className="jobs-list-text035">
                        <span>&gt; 30000k</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame33">
                      <img
                        alt="Ellipse53310"
                        src="/external/ellipse53310-rx8-200h.png"
                        className="jobs-list-ellipse51"
                      />
                      <span className="jobs-list-text037">
                        <span>&gt; 50000k</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame341">
                      <img
                        alt="Ellipse53311"
                        src="/external/ellipse53311-3uh4-200h.png"
                        className="jobs-list-ellipse52"
                      />
                      <span className="jobs-list-text039">
                        <span>&gt; 80000k</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame351">
                      <img
                        alt="Ellipse53311"
                        src="/external/ellipse53311-hyx-200h.png"
                        className="jobs-list-ellipse53"
                      />
                      <span className="jobs-list-text041">
                        <span>&gt; 100000k</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame361">
                  <span className="jobs-list-text043">Visa Sponsorship</span>
                  <div className="jobs-list-frame342">
                    <div className="jobs-list-frame311">
                      <div className="jobs-list-group11">
                        <img
                          alt="Ellipse63376"
                          src="/external/ellipse63376-8dch-200h.png"
                          className="jobs-list-ellipse61"
                        />
                        <img
                          alt="Ellipse73377"
                          src="/external/ellipse73377-e3l-200h.png"
                          className="jobs-list-ellipse71"
                        />
                      </div>
                      <span className="jobs-list-text044">Yes</span>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame362">
                  <span className="jobs-list-text045">Remote</span>
                  <div className="jobs-list-frame343">
                    <div className="jobs-list-frame312">
                      <div className="jobs-list-group12">
                        <img
                          alt="Ellipse63376"
                          src="/external/ellipse63376-8dch-200h.png"
                          className="jobs-list-ellipse62"
                        />
                        <img
                          alt="Ellipse73377"
                          src="/external/ellipse73377-e3l-200h.png"
                          className="jobs-list-ellipse72"
                        />
                      </div>
                      <span className="jobs-list-text046">Yes</span>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame38">
                  <span className="jobs-list-text047">
                    <span>Work experience</span>
                  </span>
                  <div className="jobs-list-frame344">
                    <div className="jobs-list-frame313">
                      <div className="jobs-list-group13">
                        <img
                          alt="Ellipse63312"
                          src="/external/ellipse63312-5e2g-200h.png"
                          className="jobs-list-ellipse63"
                        />
                        <img
                          alt="Ellipse73312"
                          src="/external/ellipse73312-5nd3-200h.png"
                          className="jobs-list-ellipse73"
                        />
                      </div>
                      <span className="jobs-list-text049">Any experience</span>
                    </div>
                    <div className="jobs-list-frame321">
                      <img
                        alt="Ellipse53312"
                        src="/external/ellipse53312-avrw-200h.png"
                        className="jobs-list-ellipse54"
                      />
                      <span className="jobs-list-text050">Intership</span>
                    </div>
                    <div className="jobs-list-frame331">
                      <img
                        alt="Ellipse53312"
                        src="/external/ellipse53312-6kfh-200h.png"
                        className="jobs-list-ellipse55"
                      />
                      <span className="jobs-list-text051">
                        Entry level (0-2)
                      </span>
                    </div>
                    <div className="jobs-list-frame332">
                      <img
                        alt="Ellipse53312"
                        src="/external/ellipse53312-6kfh-200h.png"
                        className="jobs-list-ellipse56"
                      />
                      <span className="jobs-list-text052">Mid level (3-5)</span>
                    </div>
                    <div className="jobs-list-frame333">
                      <img
                        alt="Ellipse53312"
                        src="/external/ellipse53312-6kfh-200h.png"
                        className="jobs-list-ellipse57"
                      />
                      <span className="jobs-list-text053">
                        Senior level (5-10)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame39">
                  <span className="jobs-list-text054">
                    <span>Type of employment</span>
                  </span>
                  <div className="jobs-list-frame345">
                    <div className="jobs-list-frame314">
                      <div className="jobs-list-group4">
                        <img
                          alt="Rectangle24876"
                          src="/external/rectangle24876-6ps-200h.png"
                          className="jobs-list-rectangle2"
                        />
                        <img
                          alt="Check4878"
                          src="/external/check4878-90b.svg"
                          className="jobs-list-check"
                        />
                      </div>
                      <span className="jobs-list-text056">
                        <span>Full-time</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame322">
                      <img
                        alt="Rectangle24875"
                        src="/external/rectangle24875-3o1-200h.png"
                        className="jobs-list-rectangle21"
                      />
                      <span className="jobs-list-text058">
                        <span>Temporary</span>
                      </span>
                    </div>
                    <div className="jobs-list-frame334">
                      <img
                        alt="Rectangle34878"
                        src="/external/rectangle34878-oh9x-200h.png"
                        className="jobs-list-rectangle3"
                      />
                      <span className="jobs-list-text060">
                        <span>Part-time</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="jobs-list-cards">
              <div className="jobs-list-frame71">
                <div className="jobs-list-frame46">
                  <span className="jobs-list-text062">
                    <span>3177 Jobs</span>
                  </span>
                  <div className="jobs-list-frame141">
                    <div className="jobs-list-frame45">
                      <img
                        alt="FunnelSimple3745"
                        src="/external/funnelsimple3745-utoo.svg"
                        className="jobs-list-funnel-simple"
                      />
                      <span className="jobs-list-text064">
                        <span>Filter by</span>
                      </span>
                    </div>
                    <img
                      alt="CaretDown3750"
                      src="/external/caretdown3750-x9eu.svg"
                      className="jobs-list-caret-down"
                    />
                  </div>
                </div>
                <div className="jobs-list-frame26">
                  <div className="jobs-list-frame17"></div>
                  <div className="jobs-list-frame23">
                    <div className="jobs-list-frame28">
                      <div className="jobs-list-container5">
                        <div className="jobs-list-frame29">
                          <div className="jobs-list-frame27">
                            <span className="jobs-list-text066">
                              <span>Linear company</span>
                            </span>
                          </div>
                          <div className="jobs-list-frame30">
                            <span className="jobs-list-text068">
                              <span>Software Engineer</span>
                            </span>
                            <div className="jobs-list-frame25">
                              <span className="jobs-list-text070">
                                <span>New post</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="jobs-list-frame3001">
                        <div className="jobs-list-frame18">
                          <img
                            alt="MapPinLine2391"
                            src="/external/mappinline2391-jsp.svg"
                            className="jobs-list-map-pin-line1"
                          />
                          <span className="jobs-list-text072">
                            <span>Brussels</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse32396"
                          src="/external/ellipse32396-yefx-200h.png"
                          className="jobs-list-ellipse3"
                        />
                        <div className="jobs-list-frame20">
                          <span className="jobs-list-text074">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse22310"
                          src="/external/ellipse22310-dljp-200h.png"
                          className="jobs-list-ellipse2"
                        />
                        <div className="jobs-list-frame21">
                          <img
                            alt="CurrencyDollar2310"
                            src="/external/currencydollar2310-73qc.svg"
                            className="jobs-list-currency-dollar"
                          />
                          <span className="jobs-list-text076">
                            <span>50-55k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse12310"
                          src="/external/ellipse12310-u7tl-200h.png"
                          className="jobs-list-ellipse1"
                        />
                        <div className="jobs-list-frame22">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="jobs-list-clock"
                          />
                          <span className="jobs-list-text078">
                            <span>29 min ago</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame271">
                  <div className="jobs-list-frame171"></div>
                  <div className="jobs-list-frame231">
                    <div className="jobs-list-frame281">
                      <div className="jobs-list-frame291">
                        <div className="jobs-list-frame272">
                          <span className="jobs-list-text080">
                            <span>Notion</span>
                          </span>
                        </div>
                        <div className="jobs-list-frame3002">
                          <span className="jobs-list-text082">
                            <span>Junior UI Designer</span>
                          </span>
                        </div>
                      </div>
                      <div className="jobs-list-frame3003">
                        <div className="jobs-list-frame181">
                          <img
                            alt="MapPinLine2311"
                            src="/external/mappinline2311-86tn.svg"
                            className="jobs-list-map-pin-line2"
                          />
                          <span className="jobs-list-text084">
                            <span>Madrid</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse32312"
                          src="/external/ellipse32312-fh0c-200h.png"
                          className="jobs-list-ellipse31"
                        />
                        <div className="jobs-list-frame201">
                          <span className="jobs-list-text086">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse22312"
                          src="/external/ellipse22312-utz9-200h.png"
                          className="jobs-list-ellipse21"
                        />
                        <div className="jobs-list-frame211">
                          <img
                            alt="CurrencyDollar2313"
                            src="/external/currencydollar2313-nf3u.svg"
                            className="jobs-list-currency-dollar1"
                          />
                          <span className="jobs-list-text088">
                            <span>30-32k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse12313"
                          src="/external/ellipse12313-egvm-200h.png"
                          className="jobs-list-ellipse11"
                        />
                        <div className="jobs-list-frame221">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="jobs-list-clock1"
                          />
                          <span className="jobs-list-text090">1 hour ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame48">
                  <div className="jobs-list-frame172"></div>
                  <div className="jobs-list-frame232">
                    <div className="jobs-list-frame282">
                      <div className="jobs-list-frame292">
                        <div className="jobs-list-frame273">
                          <span className="jobs-list-text091">
                            <span>Spline studio</span>
                          </span>
                        </div>
                        <div className="jobs-list-frame3004">
                          <span className="jobs-list-text093">
                            <span>Technical Support Engineer</span>
                          </span>
                        </div>
                      </div>
                      <div className="jobs-list-frame3005">
                        <div className="jobs-list-frame182">
                          <img
                            alt="MapPinLine4756"
                            src="/external/mappinline4756-hddb.svg"
                            className="jobs-list-map-pin-line3"
                          />
                          <span className="jobs-list-text095">
                            <span>United States</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse34761"
                          src="/external/ellipse34761-ujf4-200h.png"
                          className="jobs-list-ellipse32"
                        />
                        <div className="jobs-list-frame202">
                          <span className="jobs-list-text097">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse24767"
                          src="/external/ellipse24767-zu9-200h.png"
                          className="jobs-list-ellipse22"
                        />
                        <div className="jobs-list-frame212">
                          <img
                            alt="CurrencyDollar4769"
                            src="/external/currencydollar4769-u6ye.svg"
                            className="jobs-list-currency-dollar2"
                          />
                          <span className="jobs-list-text099">
                            <span>50-52k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse14773"
                          src="/external/ellipse14773-aixm-200h.png"
                          className="jobs-list-ellipse12"
                        />
                        <div className="jobs-list-frame222">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="jobs-list-clock2"
                          />
                          <span className="jobs-list-text101">9 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame251">
                  <div className="jobs-list-frame173"></div>
                  <div className="jobs-list-frame233">
                    <div className="jobs-list-frame283">
                      <div className="jobs-list-frame293">
                        <div className="jobs-list-frame274">
                          <span className="jobs-list-text102">
                            <span>Raycast corp</span>
                          </span>
                        </div>
                        <div className="jobs-list-frame3006">
                          <span className="jobs-list-text104">
                            <span>Product Designer</span>
                          </span>
                        </div>
                      </div>
                      <div className="jobs-list-frame3007">
                        <div className="jobs-list-frame183">
                          <img
                            alt="MapPinLine2364"
                            src="/external/mappinline2364-im1e.svg"
                            className="jobs-list-map-pin-line4"
                          />
                          <span className="jobs-list-text106">
                            <span>London</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse32369"
                          src="/external/ellipse32369-ka9m-200h.png"
                          className="jobs-list-ellipse33"
                        />
                        <div className="jobs-list-frame203">
                          <span className="jobs-list-text108">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse22375"
                          src="/external/ellipse22375-fxu-200h.png"
                          className="jobs-list-ellipse23"
                        />
                        <div className="jobs-list-frame213">
                          <img
                            alt="CurrencyDollar2377"
                            src="/external/currencydollar2377-tzn.svg"
                            className="jobs-list-currency-dollar3"
                          />
                          <span className="jobs-list-text110">
                            <span>40-42k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse12381"
                          src="/external/ellipse12381-qh5s-200h.png"
                          className="jobs-list-ellipse13"
                        />
                        <div className="jobs-list-frame223">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="jobs-list-clock3"
                          />
                          <span className="jobs-list-text112">
                            12 hours ago
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame471">
                  <div className="jobs-list-frame174"></div>
                  <div className="jobs-list-frame234">
                    <div className="jobs-list-frame284">
                      <div className="jobs-list-frame294">
                        <div className="jobs-list-frame275">
                          <span className="jobs-list-text113">
                            <span>Loom</span>
                          </span>
                        </div>
                        <div className="jobs-list-frame3008">
                          <span className="jobs-list-text115">
                            <span>Copywriter (Growth)</span>
                          </span>
                        </div>
                      </div>
                      <div className="jobs-list-frame3009">
                        <div className="jobs-list-frame184">
                          <img
                            alt="MapPinLine4719"
                            src="/external/mappinline4719-slju.svg"
                            className="jobs-list-map-pin-line5"
                          />
                          <span className="jobs-list-text117">
                            <span>London</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse34724"
                          src="/external/ellipse34724-duqc-200h.png"
                          className="jobs-list-ellipse34"
                        />
                        <div className="jobs-list-frame204">
                          <span className="jobs-list-text119">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse24730"
                          src="/external/ellipse24730-3vqf-200h.png"
                          className="jobs-list-ellipse24"
                        />
                        <div className="jobs-list-frame214">
                          <img
                            alt="CurrencyDollar4732"
                            src="/external/currencydollar4732-o4mu.svg"
                            className="jobs-list-currency-dollar4"
                          />
                          <span className="jobs-list-text121">
                            <span>38-40k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse14736"
                          src="/external/ellipse14736-wzgh-200h.png"
                          className="jobs-list-ellipse14"
                        />
                        <div className="jobs-list-frame224">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="jobs-list-clock4"
                          />
                          <span className="jobs-list-text123">
                            15 hours ago
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="jobs-list-frame24">
                  <div className="jobs-list-frame175"></div>
                  <div className="jobs-list-frame235">
                    <div className="jobs-list-frame285">
                      <div className="jobs-list-frame295">
                        <div className="jobs-list-frame276">
                          <span className="jobs-list-text124">
                            <span>Trainline group</span>
                          </span>
                        </div>
                        <div className="jobs-list-frame3010">
                          <span className="jobs-list-text126">
                            <span>Senior UX/UI Designer</span>
                          </span>
                        </div>
                      </div>
                      <div className="jobs-list-frame225">
                        <div className="jobs-list-frame185">
                          <img
                            alt="MapPinLine1716"
                            src="/external/mappinline1716-wco.svg"
                            className="jobs-list-map-pin-line6"
                          />
                          <span className="jobs-list-text128">
                            <span>Paris</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse31716"
                          src="/external/ellipse31716-r68it-200h.png"
                          className="jobs-list-ellipse35"
                        />
                        <div className="jobs-list-frame205">
                          <img
                            alt="Clock1717"
                            src="/external/clock1717-nt2q.svg"
                            className="jobs-list-clock5"
                          />
                          <span className="jobs-list-text130">
                            <span>Full time</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse21717"
                          src="/external/ellipse21717-7ohf-200h.png"
                          className="jobs-list-ellipse25"
                        />
                        <div className="jobs-list-frame215">
                          <img
                            alt="CurrencyDollar1717"
                            src="/external/currencydollar1717-e33s.svg"
                            className="jobs-list-currency-dollar5"
                          />
                          <span className="jobs-list-text132">
                            <span>38-40k</span>
                          </span>
                        </div>
                        <img
                          alt="Ellipse11718"
                          src="/external/ellipse11718-0it-200h.png"
                          className="jobs-list-ellipse15"
                        />
                        <div className="jobs-list-frame226">
                          <img
                            alt="Clock2398"
                            src="/external/clock2398-lfe57.svg"
                            className="jobs-list-clock6"
                          />
                          <span className="jobs-list-text134">
                            20 hours ago
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="jobs-list-pagination">
                <button className="jobs-list-button03">
                  <img
                    alt="ChevronDown4712"
                    src="/external/chevrondown4712-w4l6.svg"
                    className="jobs-list-chevron-down"
                  />
                </button>
                <button className="jobs-list-button04">
                  <span className="jobs-list-text135">1</span>
                </button>
                <button className="jobs-list-button05">
                  <span className="jobs-list-text136">2</span>
                </button>
                <button className="jobs-list-button06">
                  <span className="jobs-list-text137">3</span>
                </button>
                <button className="jobs-list-button07">
                  <span className="jobs-list-text138">4</span>
                </button>
                <button className="jobs-list-button08">
                  <span className="jobs-list-text139">5</span>
                </button>
                <button className="jobs-list-button09">
                  <img
                    alt="ChevronDown4713"
                    src="/external/chevrondown4713-y7v.svg"
                    className="jobs-list-chevron-down1"
                  />
                </button>
              </div>
            </div>
            <div className="jobs-list-moreoption">
              <div className="jobs-list-frame16">
                <div className="jobs-list-frame363">
                  <span className="jobs-list-text140">
                    📩  Email me for jobs
                  </span>
                </div>
                <div className="jobs-list-frame371">
                  <span className="jobs-list-text141">
                    Subscribe to our email newsletter and stay on top of
                    recently jobs posted jobs and application tips..
                  </span>
                </div>
                <div className="jobs-list-frame72">
                  <div className="jobs-list-frame142">
                    <span className="jobs-list-text142">
                      <span>name@mail.com</span>
                    </span>
                  </div>
                  <button className="jobs-list-button10">
                    <span className="jobs-list-text144">
                      <span>Subcribe</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="jobs-list-frame186">
                <div className="jobs-list-frame364">
                  <span className="jobs-list-text146">
                    <span>🚀 Get noticed faster</span>
                  </span>
                </div>
                <div className="jobs-list-frame372">
                  <span className="jobs-list-text148">
                    <span>
                      Quis eiusmod deserunt cillum laboris magna cupidatat esse
                      labore irure quis cupidatat in.
                    </span>
                  </span>
                </div>
                <button className="jobs-list-button11">
                  <span className="jobs-list-text150">
                    <span>Upload your resume</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="jobs-list-container6">
        <footer className="jobs-list-footer">
          <img
            alt="logo"
            src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
            className="jobs-list-image"
          />
          <span className="jobs-list-text152">
            © 2021 teleportHQ, All Rights Reserved.
          </span>
          <div className="jobs-list-icon-group">
            <svg
              viewBox="0 0 950.8571428571428 1024"
              className="jobs-list-icon"
            >
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="jobs-list-icon2"
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg
              viewBox="0 0 602.2582857142856 1024"
              className="jobs-list-icon4"
            >
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default JobsList
